import classNames from 'classnames';
import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const clipId = `monogram-clip-${id}`;

  return (
    <svg
      aria-hidden
      className={classNames('monogram', className)}
      width="46"
      height="29"
      viewBox="0 0 46 29"
      {...props}
    >
      <defs>
        <clipPath id={clipId}>
          <path
            d="M19.917,0.332c-1.876,0.605-3.968,2.671-4.584,4.529c-0.323,0.973-0.369,2.35-0.311,9.33
		l0.068,8.188l0.701,1.43c3.377,6.888,13.65,6.888,16.697-0.003c1.793-4.054-0.184-10.33-3.715-11.792
		c-0.841-0.348-0.834-0.289-0.172-1.647C31.283,4.874,25.73-1.542,19.917,0.332 M28.09,13.645
		c6.264,3.973,3.461,13.715-3.948,13.715c-8.689,0-10.34-11.88-1.799-12.946c1.29-0.161,2.4-0.46,3.096-0.835
		C26.813,12.839,26.818,12.839,28.09,13.645"
          />
        </clipPath>
      </defs>
      <rect clipPath={`url(#${clipId})`} width="100%" height="100%" />
      {highlight && (
        <g clipPath={`url(#${clipId})`}>
          <rect className="monogram__highlight" width="100%" height="100%" />
        </g>
      )}
    </svg>
  );
}

export default Monogram;
