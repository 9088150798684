export const navLinks = [
  {
    label: 'projects',
    pathname: '/',
    hash: '#project-1',
  },
  {
    label: 'about',
    pathname: '/',
    hash: '#details',
  },
  // {
  //   label: 'Articles',
  //   pathname: '/articles',
  // },
  {
    label: 'contact',
    pathname: '/contact',
  },
];

export const socialLinks = [
  {
    label: 'Instagram',
    url: 'https://www.instagram.com/burhannj/',
    icon: 'instagram',
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/burhanjawed',
    icon: 'linkedIn',
  },
  {
    label: 'Github',
    url: 'https://github.com/burhanjawed',
    icon: 'github',
  },
];
